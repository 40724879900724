var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "visibility-change",
          rawName: "v-visibility-change",
          value: _vm.visibleChange,
          expression: "visibleChange",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-card", { staticStyle: { "margin-bottom": "10px" } }, [
                _vm._v("\n\n        距离考试结束还有："),
                _c("span", { staticStyle: { color: "#ff0000" } }, [
                  _vm._v(
                    _vm._s(_vm.hour) +
                      "小时" +
                      _vm._s(_vm.minute) +
                      "分钟" +
                      _vm._s(_vm.second) +
                      "秒"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { span: 6, xs: 24 },
            },
            [
              _c(
                "el-card",
                [
                  _c("p", { staticClass: "card-title" }, [_vm._v("答题卡")]),
                  _c(
                    "el-row",
                    {
                      staticClass: "card-line",
                      staticStyle: { "padding-left": "10px" },
                      attrs: { gutter: 24 },
                    },
                    [
                      _c("el-tag", { attrs: { type: "info" } }, [
                        _vm._v("未作答"),
                      ]),
                      _c("el-tag", { attrs: { type: "success" } }, [
                        _vm._v("已作答"),
                      ]),
                    ],
                    1
                  ),
                  _vm.paperData.radioList !== undefined &&
                  _vm.paperData.radioList.length > 0
                    ? _c(
                        "div",
                        [
                          _c("p", { staticClass: "card-title" }, [
                            _vm._v("单选题"),
                          ]),
                          _c(
                            "el-row",
                            { staticClass: "card-line", attrs: { gutter: 24 } },
                            _vm._l(_vm.paperData.radioList, function (item) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.label,
                                  attrs: {
                                    type: _vm.cardItemClass(
                                      item.answered,
                                      item.id
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handSave(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.sort + 1))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.paperData.multiList !== undefined &&
                  _vm.paperData.multiList.length > 0
                    ? _c(
                        "div",
                        [
                          _c("p", { staticClass: "card-title" }, [
                            _vm._v("多选题"),
                          ]),
                          _c(
                            "el-row",
                            { staticClass: "card-line", attrs: { gutter: 24 } },
                            _vm._l(_vm.paperData.multiList, function (item) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.label,
                                  attrs: {
                                    type: _vm.cardItemClass(
                                      item.answered,
                                      item.id
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handSave(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.sort + 1))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.paperData.judgeList !== undefined &&
                  _vm.paperData.judgeList.length > 0
                    ? _c(
                        "div",
                        [
                          _c("p", { staticClass: "card-title" }, [
                            _vm._v("判断题"),
                          ]),
                          _c(
                            "el-row",
                            { staticClass: "card-line", attrs: { gutter: 24 } },
                            _vm._l(_vm.paperData.judgeList, function (item) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.label,
                                  attrs: {
                                    type: _vm.cardItemClass(
                                      item.answered,
                                      item.id
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handSave(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.sort + 1))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 18, xs: 24 } },
            [
              _c("el-card", { staticClass: "qu-content" }, [
                _vm.quData.content
                  ? _c("p", [
                      _vm._v(
                        _vm._s(_vm.quData.sort + 1) +
                          "." +
                          _vm._s(_vm.quData.content)
                      ),
                    ])
                  : _vm._e(),
                _vm.quData.quType === "1" || _vm.quData.quType === "3"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.radioValue,
                              callback: function ($$v) {
                                _vm.radioValue = $$v
                              },
                              expression: "radioValue",
                            },
                          },
                          _vm._l(_vm.quData.answerList, function (item) {
                            return _c(
                              "el-radio",
                              { key: item.label, attrs: { label: item.id } },
                              [
                                _vm._v(
                                  _vm._s(item.answerOption) +
                                    "." +
                                    _vm._s(item.answerOptionValue)
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.quData.quType === "2"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.multiValue,
                              callback: function ($$v) {
                                _vm.multiValue = $$v
                              },
                              expression: "multiValue",
                            },
                          },
                          _vm._l(_vm.quData.answerList, function (item) {
                            return _c(
                              "el-checkbox",
                              { key: item.label, attrs: { label: item.id } },
                              [
                                _vm._v(
                                  _vm._s(item.answerOption) +
                                    "." +
                                    _vm._s(item.answerOptionValue)
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _vm.showPrevious
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-back" },
                          on: {
                            click: function ($event) {
                              return _vm.handPrevious()
                            },
                          },
                        },
                        [_vm._v("\n          上一题\n        ")]
                      )
                    : _vm._e(),
                  _vm.showNext
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "warning", icon: "el-icon-right" },
                          on: {
                            click: function ($event) {
                              return _vm.handNext()
                            },
                          },
                        },
                        [_vm._v("\n          下一题\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fixed_button" },
        [
          _c(
            "el-button",
            {
              staticStyle: { float: "right", "margin-top": "-10px" },
              attrs: {
                type: "primary",
                icon: "el-icon-plus",
                loading: _vm.loading,
              },
              on: {
                click: function ($event) {
                  return _vm.handHandExam()
                },
              },
            },
            [_vm._v("\n          " + _vm._s(_vm.handleText) + "\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }